import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

import indexStyles from "../styles/pages/index.module.css"

import headerVideo from "../videos/about-header-vid.mp4"
import posterImg from "../images/poster.png"

const IndexPage = ({ data }) => (
  <Layout location="home">
    <SEO title="Home" />
    <div className={ indexStyles.header }>
      <Video src={ headerVideo } poster={ posterImg } />
      <div className={ indexStyles.aboutText }>
        <div className={ indexStyles.aboutTextWrapper }>
          <h1>Great Moments</h1>
          <h1>Created Together.</h1>
          <p>Companies, organizations and individuals find working with Team ED to be a delightful key to delivering effective events to their target audience.</p>
          <Link to="/about/">Learn More</Link>
        </div>
      </div>
    </div>
    <div className={ indexStyles.servicesSection }>
      <div className={ indexStyles.servicesText }>
        <h1>Services</h1>
        <hr/>
        <p>We reimagine, reinvent and recreate exciting affairs to reach our client's audience.</p>
      </div>
      <div className={ indexStyles.servicesContent }>
        <div className={ indexStyles.services }>
          <div className={ indexStyles.service }>
            <div className={ indexStyles.imgContainer }>
              <Img fluid={ data.image1.childImageSharp.fluid } />
            </div>
            <p>Anniversaries</p>
          </div>
          <div className={ indexStyles.service }>
            <div className={ indexStyles.imgContainer }>
              <Img fluid={ data.image2.childImageSharp.fluid } />
            </div>
            <p>Celebrations</p>
          </div>
          <div className={ indexStyles.service }>
            <div className={ indexStyles.imgContainer }>
              <Img fluid={ data.image3.childImageSharp.fluid } imgStyle={{ objectFit: 'contain' }} />
            </div>
            <p>Conferences & Seminars</p>
          </div>
          <div className={ indexStyles.service }>
            <div className={ indexStyles.imgContainer }>
              <Img fluid={ data.image4.childImageSharp.fluid } />
            </div>
            <p>Product Launches</p>
          </div>
          <div className={ indexStyles.service }>
            <div className={ indexStyles.imgContainer }>
              <Img fluid={ data.image5.childImageSharp.fluid } />
            </div>
            <p>Trade Exhibits</p>
          </div>
          <div className={ indexStyles.service }>
            <div className={ indexStyles.imgContainer }>
              <Img fluid={ data.image6.childImageSharp.fluid } />
            </div>
            <p>Awards Gala Nights</p>
          </div>
        </div>
      </div>
      <Link to="/services/">See More</Link>
    </div>
    <div className={ indexStyles.contactUsSection }>
      <div className={ indexStyles.contactUsContentWrapper }>
        <div className={ indexStyles.contactUsContent }></div>
      </div>
      <div className={ indexStyles.contactUsText }>
        <div>
          <h1>Work With Us</h1>
          <p>We are a company with the experience, expertise and competence to turn any event into an unforgettable experience.</p>
          <p>We infuse creativity in every design process, making certain that innovative ideas match perfectly the goals and objectives of the event.</p>
          <Link to="/contact/">Message Us</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export const squareImg1 = graphql`
  fragment squareImg1 on File {
    childImageSharp {
      fluid(maxWidth: 125) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ANNIVERSARIES.png" }) {
      ...squareImg1
    }
    image2: file(relativePath: { eq: "CELEBRATIONS.png" }) {
      ...squareImg1
    }
    image3: file(relativePath: { eq: "CONFERENCES & SEMINARS.png" }) {
      ...squareImg1
    }
    image4: file(relativePath: { eq: "PRODUCT-LAUNCHES.png" }) {
      ...squareImg1
    }
    image5: file(relativePath: { eq: "TRADE EXHIBITS.png" }) {
      ...squareImg1
    }
    image6: file(relativePath: { eq: "AWARDS GALA NIGHTS.png" }) {
      ...squareImg1
    }
  }
`

export default IndexPage
