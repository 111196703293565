import React from 'react';

import indexStyles from '../styles/pages/index.module.css'

export default ({ src, poster }) => {
    return (
        <video
            autoPlay
            muted
            loop
            src={ src }
            poster={ poster }
            className={ indexStyles.headerVideo }
        >
            <source src={ src } type="video/mp4" />
            Your device does not support playing 'video/mp4' videos.
        </video>
    )
}